import React from 'react';
import { Badge, Tooltip } from '@chakra-ui/react';
import knowledgeType from '../../utils/knowledge-type';

const TypeBadge = ({ type, title, ...props }) => (
  <Tooltip aria-label={title} label={title} isDisabled={!title}>
    <Badge colorScheme="cyan" {...props}>
      {knowledgeType(type)}
    </Badge>
  </Tooltip>
);

export default TypeBadge;
