import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa';
import Html from '../html';

const FooterContent = ({ person, position, company }) => {
  if (person && position) {
    return (
      <>
        {person}, {position}
        {company && (
          <>
            ,{' '}
            <Box as="cite" fontStyle="normal">
              {company}
            </Box>
          </>
        )}
      </>
    );
  }
  if (position) {
    return (
      <>
        {position}
        {company && (
          <>
            ,{' '}
            <Box as="cite" fontStyle="normal">
              {company}
            </Box>
          </>
        )}
      </>
    );
  }
  return null;
};

const BlockQuote = ({ text, person, position, company, ...props }) => {
  if (!text) {
    return null;
  }
  return (
    <Box as="blockquote" maxW="600px" px={4} {...props}>
      <Box
        fontSize="md"
        fontFamily="Montserrat, Tahoma, Verdana, Segoe, sans-serif"
      >
        <Text>
          <FaQuoteLeft
            style={{
              position: 'absolute',
              marginLeft: '-20px',
              marginTop: '-5px',
              color: 'gray.400',
            }}
          />
          <Html as="span" source={text?.internal?.content || text} />
          <FaQuoteRight
            style={{
              position: 'absolute',
              marginLeft: '5px',
              marginTop: '11px',
              color: 'gray.400',
            }}
          />
        </Text>
      </Box>
      <Box
        as="footer"
        fontSize="sm"
        fontFamily="Bree Serif, Tahoma, Verdana, Segoe, sans-serif"
        color="tt.darkBlue"
        mt={3}
      >
        <FooterContent person={person} position={position} company={company} />
      </Box>
    </Box>
  );
};

export default BlockQuote;
