import { titleCase } from 'title-case';

export default (type) => {
  switch (`${type}`.toLowerCase()) {
    case 'opinion':
      return 'Blog Post';
    case 'audio visual':
      return 'Video';
    case '':
      return 'Unknown';
    default:
      return titleCase(type);
  }
};
