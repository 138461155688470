import React from 'react';
import { Badge, Tooltip } from '@chakra-ui/react';

const NewBadge = ({ isNew, ...props }) => {
  if (!isNew) {
    return null;
  }
  return (
    <Tooltip
      aria-label="Written in the last 14 days"
      label="Written in the last 14 days"
    >
      <Badge colorScheme="red" {...props}>
        New!
      </Badge>
    </Tooltip>
  );
};

export default NewBadge;
