import React from 'react';
import { graphql } from 'gatsby';
import { Container, Box, HStack, Divider, Flex } from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { CaseStudyLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import Html from 'timelesstime-ui/components/html';
import BlockQuote from 'timelesstime-ui/components/block-quote';
import NewBadge from 'timelesstime-ui/components/new-badge';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import Heading from 'timelesstime-ui/components/heading';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import PageLayout from '../components/layout';

import CaseStudyJsonLd from '../components/structured-data/case-study';
import TypeBadge from '../components/type-badge';

const CaseStudyPage = ({ data: { contentfulCaseStudy: caseStudy } }) => (
  <PageLayout
    leadInHeader={
      <CaseStudyLeadInHeader
        heading={caseStudy.heading || caseStudy.title}
        crumbs={[
          {
            url: '/case-studies/',
            title: 'Case Studies',
          },
          {
            url: `/case-studies/${caseStudy.slug}/`,
            title: caseStudy.title,
          },
        ]}
      />
    }
  >
    <SEO
      title={caseStudy.title}
      keywords={caseStudy.fields.keywords}
      description={caseStudy.metaDescription}
      canonicalPath={caseStudy.fields.path}
      thumbnail={caseStudy.jsonLdThumbnailImage}
    />
    <CaseStudyJsonLd
      slug={caseStudy.slug}
      title={caseStudy.title}
      description={caseStudy.metaDescription}
      keywords={caseStudy.fields.keywords}
      text={caseStudy.fields.plaintextContent}
      serviceSlug={caseStudy.service.slug}
      keyQuoteText={caseStudy?.quotes?.[0]?.text?.internal?.content || ''}
      createdAt={new Date(caseStudy.createdAt)}
      updatedAt={new Date(caseStudy.updatedAt)}
      thumbnailImage={caseStudy.jsonLdThumbnailImage}
      featuredImage={caseStudy.jsonLdFeaturedImage}
    />

    <Container maxW="container.lg">
      <Box mt={4}>
        <CanonicalLink color="orange.500" to="/case-studies/">
          back to case studies
        </CanonicalLink>
      </Box>
      <Heading as="h1">{caseStudy.title}</Heading>
      <HStack spacing={1}>
        <TypeBadge
          type={caseStudy.service.title}
          title={caseStudy.service.title.replace(' ...', '')}
        />
        <NewBadge isNew={caseStudy.fields.isNew} />
      </HStack>
      <Divider mt={2} mb={2} />

      <Flex mt={8} mb={8} justify="center">
        <BlockQuote
          text={caseStudy?.quotes?.[0]?.text || ''}
          person={caseStudy?.quotes?.[0]?.person || ''}
          position={caseStudy?.quotes?.[0]?.position || ''}
          company={caseStudy?.quotes?.[0]?.company || ''}
        />
      </Flex>

      <Heading as="h2">The Client and Their Requirements</Heading>
      <Html
        source={caseStudy.fields.clientRequirementsHtml}
        headerLevelStart={2}
      />

      <Heading as="h2">The Challenges</Heading>
      <Html source={caseStudy.fields.challengesHtml} headerLevelStart={2} />

      <Heading as="h2">Our Approach</Heading>
      <Html source={caseStudy.fields.approachHtml} headerLevelStart={2} />

      <Heading as="h2">The Results</Heading>
      <Html source={caseStudy.fields.resultsHtml} headerLevelStart={2} />

      <Flex mt={8} mb={8} justify="center">
        <BlockQuote
          text={caseStudy?.quotes?.[1]?.text || ''}
          person={caseStudy?.quotes?.[1]?.person || ''}
          position={caseStudy?.quotes?.[1]?.position || ''}
          company={caseStudy?.quotes?.[1]?.company || ''}
        />
      </Flex>
    </Container>

    <Jumbotron as="aside" py={8} bg="tt.darkBlue">
      <NewsletterSignup />
    </Jumbotron>
  </PageLayout>
);

export const query = graphql`
  query CaseStudySinglePageBySlug($slug: String!) {
    contentfulCaseStudy(slug: { eq: $slug }) {
      id
      title
      heading
      slug
      metaDescription
      createdAt
      updatedAt
      featuredImage {
        ...FeaturedImage
      }
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      quotes {
        id
        person
        position
        company
        # get raw text here since we need it for structured data
        text {
          internal {
            content
          }
        }
        fields {
          textHtml
        }
      }
      service {
        id
        title
        slug
      }
      fields {
        plaintextContent
        path
        url
        writtenAt
        isNew
        keywords
        clientRequirementsHtml
        challengesHtml
        approachHtml
        resultsHtml
      }
    }
  }
`;

export default CaseStudyPage;
